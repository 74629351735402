import { graphql } from "gatsby";
import React, { useContext } from "react";
import { SEO } from "../components/seo";
import ThemeContext from "../context/ThemeContext";

const MRPage = ({ data }) => {
  const mr = data.prismicMr.data;

  const themeState = useContext(ThemeContext);

  const stackCursorUp = (e) => {
    themeState.changeCursorIndex("1");
  };
  const stackCursorDown = (e) => {
    themeState.changeCursorIndex("-1");
  };

  const resetCursor = (e) => {
    themeState.toggleCursorBlendMode();
    themeState.toggleCursorBackground("transparent");
    themeState.scaleCursor(1);
  };

  const shrinkCursor = (e) => {
    themeState.toggleCursorBlendMode("difference");
    themeState.toggleCursorBackground("#fff");
    themeState.shrinkCursor(0.5);
  };

  return (
    <section className="services max-w-[1440px] mx-auto mt-[20px] md:mt-[82px]">
      <SEO
        title="Morch Rohde - Morch"
        description="MØRCH IS A FASHION SALES AGENCY FOUNDED BY LOTTE MØRCH IN 1996 AND IS ONE OF THE LEADING FASHION AGENCIES IN SCANDINAVIA."
      />

      <div className="md:max-w-[516px] mx-[17px] md:mx-[34px] flex flex-col-reverse md:flex-col">
        <div className="content pb-4 md:pb-0">
          <h4 className="uppercase">{mr.title.text}</h4>
          <div
            className="prose mb-[30px]"
            dangerouslySetInnerHTML={{ __html: mr.description.html }}
          />
          <a
            onMouseEnter={shrinkCursor}
            onMouseLeave={resetCursor}
            href={`${mr.site_url}`}
            target="_blank"
            rel="noreferrer"
            className="mt-[30px]"
          >
            <span className="backdrop">{mr.site_url}</span>
          </a>
        </div>
        <div className="md:mt-[29px] md:w-[516px] md:h-[283px] pb-4">
          <div
            className="aspect-video"
            // onMouseEnter={stackCursorDown}
            // onMouseLeave={stackCursorUp}
          >
            <video
              src={mr.mr_video.url}
              muted
              autoPlay
              loop
              playsInline
              data-wf-ignore="true"
              data-object-fit="cover"
              className="services-iframe w-full h-full object-cover"
            ></video>
          </div>
        </div>{" "}
      </div>
    </section>
  );
};

export default MRPage;

export const query = graphql`
  {
    prismicMr {
      data {
        title {
          text
        }
        description {
          html
        }
        site_url
        mr_video {
          url
        }
      }
    }
  }
`;
